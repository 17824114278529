import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.tsx";
import AdminHeader from "./AdminHeader.tsx";
import { getCookieUserId } from "../../common/common.tsx";

const AdminLayout = ({ children }: { children: React.ReactNode }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [hasAdminAccess, setHasAdminAccess] = useState(false);

    useEffect(() => {
        const userId = getCookieUserId();

        if (userId) {
            setIsAuthenticated(true);
            if (userId == "301") { // 관리자 페이지 접근하려면 회원 아아디 추가
                setHasAdminAccess(true);
            }
        } else {
            alert("로그인 후 접속하시기 바랍니다.");
            window.location.href = "/user/login";
        }

        if (isAuthenticated && !hasAdminAccess) {
            alert("관리자만 접근 가능합니다.");
            window.location.href = "/";
        }

    }, [isAuthenticated, hasAdminAccess]);

    return (
        <div className="admin-layout">
            <AdminSidebar />
            <div className="admin-main-content">
                {/*<AdminHeader />*/}
                <div className="admin-area">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default AdminLayout;
